<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="예방접종 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-plant
                  :required="true"
                  :editable="editable && !updateMode"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <!-- <div class="col-6">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="GOV_SCHEDULE_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="govScheduleTypeCd"
                  label="대관업무 구분"
                  v-model="data.govScheduleTypeCd"
                ></c-select>
              </div> -->
              <div class="col-8">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="예방접종명"
                  name="govScheduleName"
                  v-model="data.govScheduleName">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="접종시기"
                  name="govScheduleName2"
                  v-model="data.govScheduleName2">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="실시 주기"
                  name="govScheduleCycleTerm"
                  v-model="data.govScheduleCycleTerm">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :required="true"
                  :editable="editable"
                  :comboItems="cycleItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="govScheduleCycleCd"
                  label="실시 주기(단위)"
                  v-model="data.govScheduleCycleCd"
                ></c-select>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  label="접종대상"
                  name="remarks"
                  :rows="5"
                  v-model="data.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
  },
  data() {
    return {
      cycleItems: [
        { code: 'Y', codeName: '년' },
        { code: 'M', codeName: '개월' },
        { code: 'D', codeName: '일' },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'GOV_SCHEDULE_MST',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'mngUserName',
            field: 'mngUserName',
            label: '담당자',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      saveUrl: transactionConfig.env.gov.mst.insert.url,
      userListUrl: '',
      userInsertUrl: '',
      userDeleteUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        govScheduleTypeCd: null,  // 대관업무구분
        plantCd: null,  // 사업장코드
        govScheduleName: '장티푸스',  // 대관업무명
        govScheduleName2: '연중',  // 대관업무명
        govScheduleCycleTerm: '1',
        govScheduleCycleCd: 'Y',  // 법정 주기
        remarks: '장티푸스 보균자와 밀접하게 접촉하는 사람(가족 등) \n장티푸스가 유행하는 지역(동남아시아, 인도, 중동, 중남미, 아프리카)으로 여행하는 사람 및 체류자 등',  // 내용
        useFlag: 'Y',  // 사용여부
        inspType: null,  // 검사-종류
        relatedLawsCd: null,  // 검사-관련법규
        inspTarget: '',  // 검사-대상 시설
        contStatus: null,  // 계약-상태
        contType: null,  // 계약-구분
        contVendor: '',  // 계약-업체
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.env.gov.mst.get.url;
      this.saveUrl = transactionConfig.env.gov.mst.insert.url;
      this.userListUrl = selectConfig.env.gov.user.list.url;
      this.userInsertUrl = transactionConfig.env.gov.user.insert.url;
      this.userDeleteUrl = transactionConfig.env.gov.user.delete.url;
      this.getDetail();
    },
    getDetail() {
      // if (this.param.govScheduleMstId) {
      //   this.$http.url = this.detailUrl;
      //   this.$http.param = {govScheduleMstId: this.param.govScheduleMstId}
      //   this.$http.type = 'GET';
      //   this.$http.request((_result) => {
      //     this.data = _result.data;
      //     this.updateMode = true;
      //     this.$set(this.attachInfo, 'taskKey', this.param.govScheduleMstId)
      //     this.getUserList();
      //   },);
      // }
    },
    getUserList() {
      if (this.param.govScheduleMstId) {
        this.$http.url = this.userListUrl;
        this.$http.param = {govScheduleMstId: this.param.govScheduleMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    reset() {
    },
    saveInfo() {
      if (this.param.govScheduleMstId) {
        this.saveUrl = transactionConfig.env.gov.mst.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.gov.mst.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.govScheduleMstId = result.data.govScheduleMstId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.govScheduleMstId = result.data.govScheduleMstId
      if (this.mappingType == 'POST') {
        this.$set(this.attachInfo, 'taskKey', result.data.govScheduleMstId)
        this.$set(this.attachInfo, 'isSubmit', uid())
        this.$emit('insertGovScheduleInfo', result.data.govScheduleMstId);
      }
      this.getDetail();
    },
    addrow() {
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { mngUserId: item.userId }) === -1) {
            saveData.push({
              editFlag: 'C',
              govScheduleMstId: this.param.govScheduleMstId,
              mngUserId: item.userId,
            });
          }
        });
        this.$http.url = this.userInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getUserList();
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.userDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>