var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "예방접종 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.updateMode,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "예방접종명",
                            name: "govScheduleName",
                          },
                          model: {
                            value: _vm.data.govScheduleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleName", $$v)
                            },
                            expression: "data.govScheduleName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "접종시기",
                            name: "govScheduleName2",
                          },
                          model: {
                            value: _vm.data.govScheduleName2,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleName2", $$v)
                            },
                            expression: "data.govScheduleName2",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "실시 주기",
                            name: "govScheduleCycleTerm",
                          },
                          model: {
                            value: _vm.data.govScheduleCycleTerm,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleCycleTerm", $$v)
                            },
                            expression: "data.govScheduleCycleTerm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            comboItems: _vm.cycleItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "govScheduleCycleCd",
                            label: "실시 주기(단위)",
                          },
                          model: {
                            value: _vm.data.govScheduleCycleCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "govScheduleCycleCd", $$v)
                            },
                            expression: "data.govScheduleCycleCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "접종대상",
                            name: "remarks",
                            rows: 5,
                          },
                          model: {
                            value: _vm.data.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remarks", $$v)
                            },
                            expression: "data.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }